// project import
import React, { useEffect, useState } from 'react';
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-party/Notistack';
import { SearchProvider } from './hooks/useSearchContext';
import { FormattedMessage } from "react-intl";
// auth-provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import VersionCheck from './utils/versionCheck';
import { Box, IconButton, Typography, Modal, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "@mui/material/styles";


// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

function App() {
  //  const intl = useIntl();
   const versionCheckUrl = '/version.json';
   const [openModal, setOpenModal] = useState(false);
   const theme = useTheme();

   useEffect(() => {  
    const handleVersionChange = (event) => {
      console.log('Version changed:', event.detail);
      // Add your logic here for handling the version change
      // setOpenModal(true);
      window.location.href = localStorage.getItem('redirectLogin').replace(/"/g, '')+ 'auth/logout';
    };

    // Add event listener for versionChange
    window.addEventListener('versionChange', handleVersionChange);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('versionChange', handleVersionChange);
    };
  }, []); 
  const handleCloseModal =  () => {
    setOpenModal(false);
    window.location.reload();
    // try {
    //   await logout();
    //   // navigate(`/archive/search`, {
    //   //   state: {
    //   //     from: ''
    //   //   }
    //   // });
    // } catch (err) {
    //   console.error(err);
    // }
  };

  const buildAlert = "Wir haben eine neue Version v1.0.1 für dich. Bitte klicke auf den Button \"Aktualisieren\", um die neue Version nutzen zu können. Danach wirst du automatisch ausgeloggt. Dieser Vorgang ist normal und du kannst dich anschliessend wieder einloggen. <br><br>Wenn dieses Fenster nochmals auftaucht, musst du den Zwischenspeicher / Cache löschen. Dies kann mit folgender Tastenkombination ausgeführt werden:<br> &emsp;&emsp;-&emsp;Ctrl. (control) + F5.";
  return (
    <>
     <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          <AuthProvider>
            <>
              <Notistack>
                <SearchProvider>
                  <Routes />
                </SearchProvider>
                <Snackbar />
              </Notistack>
            </>
          </AuthProvider>
        </ScrollTop>
        <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%", // Set a height for the modal
            maxWidth: "50vw", 
            maxHeight: "35vh", // Ensure it does not exceed the viewport height
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 0, // Adjust padding if needed
            outline: 0,
            display: "flex",
            flexDirection: "column", // Stack children vertically
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              p: 2,
              alignItems: "center", // Center items vertically
              justifyContent: "space-between", // Space between items
              width: "100%", // Set width to 100% to fill the parent Box
            }}
          >
            <Typography><FormattedMessage id="newversion_available" /></Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon twoToneColor={theme.palette.secondary.main} />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 1, // Allow this box to grow and fill the remaining space
              overflow: "hidden", // Hide overflow if needed
              padding: "16px"
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: buildAlert }} />
            <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{ flex: 1, ml: 1, float: "right" }}
          >
            <FormattedMessage id="update" />
          </Button>
          </Box> 
        </Box>
      </Modal>
      </Locales>
      {/* </RTLLayout> */}
       <VersionCheck url={versionCheckUrl} frequency={1000 * 30 * 1} /> 
    </ThemeCustomization>
    
    </>
   
    
  );
}

export default App;
