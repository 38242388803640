import { useEffect, useState } from 'react';
import axios from 'axios';

const VersionCheck = ({ url, frequency = 1000 * 30 * 1 }) => {
    const [currentHash, setCurrentHash] = useState(() => localStorage.getItem('hash') || '');

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await axios.get(`${url}?t=${new Date().getTime()}`);
                const hash = response.data.hash;

                // if (currentHash === '') {
                //     setCurrentHash(hash);
                // }

                if (hasHashChanged(currentHash, hash)&&currentHash) {
                    console.log('dist changed');
                    window.dispatchEvent(new CustomEvent('versionChange', { detail: true }));
                }

                localStorage.setItem('hash', hash);
                setCurrentHash(hash);
            } catch (error) {
                console.error(error, 'Could not get version');
            }
        };

        const intervalId = setInterval(checkVersion, frequency);
        return () => clearInterval(intervalId);
    }, [url, frequency, currentHash]);

    const hasHashChanged = (currentHash, newHash) => {
        return currentHash !== newHash || !currentHash;
    };

    return null; // This component doesn't render anything
};

export default VersionCheck; // Ensure this line is present